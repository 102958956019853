import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@iotshaman/shaman-angular';

import { SidebarService } from './sidebar.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})
export class SidebarComponent implements OnInit {

  isVisible: boolean = false;
  menuItems: SidebarMenuItem[] = [];
  private _isLoggedIn: boolean = false;
  get isLoggedIn(): boolean { return this._isLoggedIn; }

  constructor(
    private router: Router,
    private authService: AuthService,
    private sidebarService: SidebarService) { }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe(rslt => this._isLoggedIn = rslt);
    this.sidebarService.isVisible.subscribe(val => this.isVisible = val);
    this.menuItems = [
      {
        label: 'Dashboard',
        visible: false,
        icon: ['fas', 'tachometer-alt'],
        command: () => this.onClickLink(['/dashboard']),
        permission: 'shaman-invoicing:admin'
      },
      {
        label: 'Manage Accounts',
        visible: false,
        icon: ['fas', 'user-tie'],
        command: () => this.onClickLink(['/accounts']),
        permission: 'shaman-invoicing:admin'
      },
      {
        label: 'Manage Agreements',
        visible: false,
        icon: ['fas', 'file-invoice'],
        command: () => this.onClickLink(['/agreements']),
        permission: 'shaman-invoicing:admin'
      }
    ];
    this.loadSidebarRoutes();
  }

  onSidebarHide = () => {
    this.sidebarService.setVisibility(false);
  }

  onClickLink = (link: string[]) => {
    this.router.navigate(link);
    this.sidebarService.setVisibility(false);
  }

  private loadSidebarRoutes = () => {
    this.authService.loggedInUser
      .pipe(take(1))
      .subscribe(user => {
        this.menuItems = this.menuItems.map(item => {
          if (!item.permission || user.permissions.indexOf(item.permission) > -1) {
            item.visible = true;
          }
          return item;
        });
      });
  }

}

export interface SidebarMenuItem {
  label: string;
  visible: boolean;
  icon?: string[];
  command?: (event?: any) => void;
  items?: SidebarMenuItem[];
  permission?: string;
}
