import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import {
  AuthService, ComponentsModule, AuthGuard, AuthInterceptor, PERMISSIONS_TOKEN,
  ConfigService, CONFIGURATION_FILE_PATH
} from '@iotshaman/shaman-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeSharedModule } from './_shared/modules/font-awesome.module';
import { ToastService } from './_shared/services/toast.service';
import { LayoutModule } from './_layout/layout.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    ComponentsModule,
    FontAwesomeSharedModule,
    HttpClientModule,
    ConfirmDialogModule,
    ToastModule,
    LayoutModule
  ],
  providers: [
    { provide: CONFIGURATION_FILE_PATH, useValue: '/assets/data/client-config.json' },
    ConfigService,
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: PERMISSIONS_TOKEN,
      useValue: ['shaman-invoicing:admin']
    },
    ConfirmationService,
    MessageService,
    ToastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
