import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable()
export class ToastService {
  
  constructor(private messageService: MessageService) { }

  error = (message: string, header: string = 'Request Failed'): void => {
    this.messageService.add({severity: 'error', summary: header, detail: message});
  }

  success = (message: string, header: string = 'Request Successful'): void => {
    this.messageService.add({severity: 'success', summary: header, detail: message});
  }

}
