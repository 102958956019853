import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizeComponent, NotAuthorizedComponent, AuthGuard } from '@iotshaman/shaman-angular';

const routes: Routes = [
  { 
    path: 'dashboard', 
    canActivate: [AuthGuard],
    loadChildren: () => import("./dashboard/dashboard.module").then(
      module => module.DashboardModule
    )
  },
  { 
    path: 'accounts', 
    canActivate: [AuthGuard],
    loadChildren: () => import("./manage-accounts/manage-accounts.module").then(
      module => module.ManageAccountsModule
    )
  },
  { 
    path: 'agreements', 
    canActivate: [AuthGuard],
    loadChildren: () => import("./manage-agreements/manage-agreements.module").then(
      module => module.ManageAgreementsModule
    )
  },
  { path: 'authorize', component: AuthorizeComponent },
  { path: 'not-authorized', component: NotAuthorizedComponent },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
