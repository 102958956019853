<header>
  <div class="iots-logo">
    <img src="/assets/images/iotlogowhite.svg" alt="IoT Shaman - Unlocking the Mysteries of IoT">
  </div>
  <div class="site-name">
    <a href="/">Shaman Invoicing</a>
  </div>
  <div class="pull-right header-navigation">
    <div *ngIf="!user" class="header-button" (click)="onClickLogin()">
      <fa-icon [icon]="['fas', 'user']"></fa-icon>
    </div>
    <div *ngIf="!!user" class="header-button" (click)="userMenu.toggle($event)">
      <div class="display-name p-mr-3">{{displayName}}</div>
      <fa-icon [icon]="['fas', 'user-cog']"></fa-icon>
    </div>
    <div *ngIf="!!user" class="header-button" (click)="toggleSidebar()">
      <fa-icon [icon]="['fas', 'bars']"></fa-icon>
    </div>
    <p-menu #userMenu [popup]="true" [model]="userMenuItems" appendTo="body"></p-menu>
  </div>
</header>
