import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, LoggedInUser } from '@iotshaman/shaman-angular';

import { MenuItem } from 'primeng/api';

import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  userMenuItems: MenuItem[];
  user: LoggedInUser;

  constructor(
    private router: Router,
    private authService: AuthService,
    private sidebarService: SidebarService) { }

  ngOnInit(): void {
    this.authService.loggedInUser.subscribe(user => {
      this.user = user;
      this.loadUserMenu();
    });
    this.authService.isLoggedIn.subscribe(loggedIn => {
      if (!loggedIn) this.user = null;
    });
  }

  get displayName(): string {
    if (!this.user) return '';
    let {firstName, lastName} = this.user;
    let name = `${firstName.substring(0, 1)}. ${lastName}`;
    if (name.length > 20) name = `${name.substring(0, 18)}...`;
    return name;
  }

  toggleSidebar = () => {
    this.sidebarService.toggleVisibility();
  }

  onClickLogin = () => {
    this.authService.login();
  }

  private loadUserMenu = () => {
    this.userMenuItems = [
      {
        label: 'Sign Out',
        icon: 'pi pi-sign-out',
        command: () => {
          this.authService.logout();
          window.location.href = 'https://www.iotshaman.com/'
        }
      }
    ]
  }

}
