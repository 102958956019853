<div *ngIf="isLoggedIn" class="sidebar-wrapper">
  <p-sidebar [(visible)]="isVisible" position="right" [baseZIndex]="10000" (onHide)="onSidebarHide()">
    <div class="sidebar-menu">
      <ul>
        <li class="header-menu">
          <span>Navigation</span>
        </li>
        <li *ngFor="let item of menuItems" class="menu-item"
          (click)="item.command($event)" [hidden]="!item.visible">
          <fa-icon [icon]="item.icon" size="lg" [fixedWidth]="true"></fa-icon>
          <span>{{ item.label }}</span>
        </li>
      </ul>
    </div>
  </p-sidebar>
</div>
