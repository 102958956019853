import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SpinnerService {

  private _visible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get visible(): Observable<boolean> { return this._visible.asObservable(); }

  constructor() { }

  startSpinner = () => {
    this._visible.next(true);
  }

  stopSpinner = () => {
    this._visible.next(false);
  }

}
